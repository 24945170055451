<template>
    <div>
        <div class="form-search-box flex end">
            <a-form layout="inline">
                <a-form-item label="设计师">
                    <a-select v-model="search.designer_id" style="width: 90px">
                        <a-select-option :value="0">未选择</a-select-option>
                        <a-select-option v-for="(item, index) in designerList" :key="index" :value="item.account_id">{{
                            item.name }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="日期范围">
                    <a-range-picker @change="changeOperateTime" v-model="search.data" valueFormat="YYYY-MM-DD"
                        style="width: 200px;" />
                </a-form-item>
                <a-form-item label="搜索">
                    <a-input v-model="search.keyword" style="width: 120px"></a-input>
                </a-form-item>
                <a-form-item>
                    <a-button @click="searchAct" type="primary">查询</a-button>
                    <a-button @click="cancelAct" class="ml10">取消</a-button>
                </a-form-item>
            </a-form>
        </div>
        <div class="mt20 pd20 bg-w">
            <div class="xhm-table-gray mt20">
                <a-table rowKey="order_id" :columns="columns" :pagination="pagination" @change="change"
                    :data-source="list" :loading="loading">
                    <!-- 订单ID/下单时间 -->
                    <template slot="consult" slot-scope="record,index">
                        <div>{{ record.order_id }}</div>
                        <div>{{ record.consult }}</div>
                    </template>
                    <!-- 客户群/微信 -->
                    <template slot="crowd" slot-scope="record,index">
                        <!-- <div>{{ record.crowd }}</div> -->
                        <a-tag color="blue" @click="copyCode(record.crowd)">{{ record.crowd }}</a-tag>
                        <div class="mt5">{{ record.weixin }}</div>
                    </template>
                    <!-- 交付时间/账号 -->
                    <template slot="deliver_time" slot-scope="record,index">
                        <a-tag color="purple">{{ record.deliver_time }}</a-tag>
                        <!-- <div>{{ record.deliver_time }}</div> -->
                        <div class="mt5">
                            {{ record.firm_name }}(ID:{{ record.firm_id }})
                        </div>
                    </template>
                    <!-- 交付 -->
                    <template slot="deliver_status" slot-scope="record,index">
                        <div>
                            <a-tag color="red" v-if="record.deliver_status == 0">待交付</a-tag>
                            <a-tag color="pink" v-if="record.deliver_status == 2">待修改</a-tag>
                            <a-tag color="purple" v-if="record.deliver_status == 1">待反馈</a-tag>
                            <a-tag color="orange" v-if="record.deliver_status == 4">没资料</a-tag>
                            <a-tag color="blue" v-if="record.deliver_status == 5">部分制作</a-tag>
                            <a-tag color="green" v-if="record.deliver_status == 3">已完成</a-tag>
                            <a-tag color="#f50" v-if="record.deliver_status == 7">已退款</a-tag>
                        </div>
                        <div class="mt5">{{ record.name }}</div>
                    </template>
                    <!-- 订单类型 -->
                    <template slot="order_type" slot-scope="record,index">
                        <div>
                            <a-tag color="#2db7f5" v-if="record.type == 1">标</a-tag>
                            <a-tag color="#f50" v-if="record.type == 2">企</a-tag>
                            <a-tag color="#87d068" v-if="record.type == 3">图</a-tag>
                        </div>
                        <div class="mt5">{{ record.num }}</div>
                    </template>
                     <!-- 订单备注 -->
                     <template slot="notes" slot-scope="record,index">
                        <span class="ftwblod cl-black">{{ record.notes }}</span>
                    </template>
                     <!-- 设计师备注 -->
                     <template slot="designer_notes" slot-scope="record,index">
                        <span class="ftwblod text-red">{{ record.designer_notes }}</span>
                    </template>
                    <!-- 操作 -->
                    <template slot="action" slot-scope="record,index">
                        <div>
                            <a-button type="primary" shape="circle" icon="edit" @click="editOrder(record)" />
                        </div>
                        <div class="mt5">
                            <a-button shape="circle" icon="link" @click="toLogin(record)" />
                        </div>
                    </template>
                    <!-- 关联订单 -->
                    <a-table slot="expandedRowRender" slot-scope="record,index" rowKey="order_id" :columns="correlation"
                        :data-source="record.correlation">

                        <!-- 交付 -->
                        <template slot="deliver_status" slot-scope="record,index">
                            <div>
                                <a-tag color="red" v-if="record.deliver_status == 0">待交付</a-tag>
                                <a-tag color="pink" v-if="record.deliver_status == 2">待修改</a-tag>
                                <a-tag color="purple" v-if="record.deliver_status == 1">待反馈</a-tag>
                                <a-tag color="orange" v-if="record.deliver_status == 4">没资料</a-tag>
                                <a-tag color="blue" v-if="record.deliver_status == 5">部分制作</a-tag>
                                <a-tag color="green" v-if="record.deliver_status == 3">已完成</a-tag>
                                <a-tag color="#f50" v-if="record.deliver_status == 7">已退款</a-tag>
                            </div>
                            <div class="mt5">{{ record.name }}</div>
                        </template>
                        <!-- 订单类型 -->
                        <template slot="order_type" slot-scope="record,index">
                            <a-tag color="#2db7f5" v-if="record.type == 1">标</a-tag>
                            <a-tag color="#f50" v-if="record.type == 2">企</a-tag>
                            <a-tag color="#87d068" v-if="record.type == 3">图</a-tag>
                            <span>/ </span>
                            {{ record.num }}
                        </template>
                        <!-- 操作 -->
                        <template slot="action" slot-scope="record,index">
                            <div>
                                <a-button type="primary" shape="circle" icon="edit" @click="editOrder(record)" />
                            </div>
                            <div class="mt5">
                                <a-button shape="circle" icon="link" @click="toLogin(record)" />
                            </div>
                        </template>
                    </a-table>

                </a-table>
            </div>
        </div>

        <a-modal width="540px" :title="title" :visible="show" @cancel="show = false" @ok="save">
            <div class="flex">
                <div style="width: 235px;" class="flex alcenter">
                    <div style="width: 80px;">交付时间</div>
                    <a-date-picker v-model="orderData.deliver_time" valueFormat="YYYY-MM-DD" />
                </div>
                <div style="width: 235px;" class="ml20 flex alcenter">
                    <div style="width: 70px;">交付状态：</div>
                    <a-select v-model="orderData.deliver_status" style="width: 165px;">
                        <a-select-option :value="0">待交付</a-select-option>
                        <a-select-option :value="2">待修改</a-select-option>
                        <a-select-option :value="1">待反馈</a-select-option>
                        <a-select-option :value="4">没资料</a-select-option>
                        <a-select-option :value="5">部分制作</a-select-option>
                        <a-select-option :value="3">已完成</a-select-option>
                        <a-select-option :value="7">已退款</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="mt10 flex">
                <div style="width: 235px;" class="flex alcenter">
                    <div style="width: 70px;">账号名称：</div>
                    <a-input v-model="orderData.firm_name" style="width: 165px;" />
                </div>
                <div style="width: 235px;" class="flex alcenter ml20">
                    <div style="width: 70px;">账号ID：</div>
                    <a-input v-model="orderData.firm_id" style="width: 165px;" />
                </div>
            </div>
            <div class="mt10 flex">
                <div style="width: 235px;" class="flex alcenter">
                    <div style="width: 70px;">客户群：</div>
                    <a-input v-model="orderData.crowd" style="width: 165px;" />
                </div>
                <div style="width: 235px;" class="flex alcenter ml20">
                    <div style="width: 70px;">设计师：</div>
                    <a-select v-model="orderData.designer_id" style="width: 165px;">
                        <a-select-option :value="0">未分配</a-select-option>
                        <a-select-option v-for="(item, index) in designerList" :key="index" :value="item.account_id">{{
                            item.name }}</a-select-option>
                    </a-select>
                </div>

            </div>
            <div class="mt10 flex">
                <div style="width: 235px;" class="flex alcenter">
                    <div style="width: 70px;">人数：</div>
                    <a-input v-model="orderData.num" style="width: 165px;" type="number" />
                </div>
                <div style="width: 235px;" class="flex alcenter ml20">
                    <div style="width: 70px;">排序：</div>
                    <a-input v-model="orderData.sort" style="width: 165px;" type="number" />
                </div>
            </div>
            <div class="mt12 flex alcenter">
                <div style="width: 80px;">备注：</div>
                <a-textarea v-model="orderData.designer_notes" :rows="4" />
            </div>
        </a-modal>
    </div>
</template>

<script>
import { apiDeliverList, apiDeliverSave, apiDeliverLogin, apiDesignerList } from '../../utils/js/api';
export default {
    props: {
        status: {
            type: Number,
            default: 8
        },
    },
    data() {
        return {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            search: {
                keyword: '',
                designer_id: 0,
                data: ['', '']
            },
            show: false,
            title: '添加客户',
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            columns: [
                { title: '订单id/下单日期', key: 'consult', align: 'left', scopedSlots: { customRender: 'consult' } },
                { title: '类型/数量', key: 'order_type', align: 'left', scopedSlots: { customRender: 'order_type' } },
                { title: '客户群/微信', key: 'crowd', align: 'left', scopedSlots: { customRender: 'crowd' } },
                { title: '交付时间/账号', key: 'deliver_time', align: 'left', scopedSlots: { customRender: 'deliver_time' } },
                { title: '交付状态/设计师', key: 'deliver_status', align: 'left', scopedSlots: { customRender: 'deliver_status' } },
                { title: '订单备注', key: 'notes', align: 'left', width: 300, scopedSlots: { customRender: 'notes' } },
                { title: '设计师备注', key: 'designer_notes', align: 'left', width: 300, scopedSlots: { customRender: 'designer_notes' }},
                { title: '排序', dataIndex: 'sort', align: 'left'},
                { title: '操作', key: 'action', align: 'left', scopedSlots: { customRender: 'action' } },
            ],
            correlation: [
                { title: '订单id', dataIndex: 'order_id', align: 'left' },
                { title: '下单日期', dataIndex: 'consult', align: 'left' },

                { title: '交付状态', key: 'deliver_status', align: 'left', scopedSlots: { customRender: 'deliver_status' } },
                { title: '类型/数量', key: 'order_type', align: 'left', scopedSlots: { customRender: 'order_type' } },
                { title: '交付时间', dataIndex: 'deliver_time', align: 'left' },
                { title: '订单备注', dataIndex: 'notes', align: 'left', width: 300 },
                { title: '设计师备注', dataIndex: 'designer_notes', align: 'left', width: 300 },
                // { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },
            ],
            list: [],
            orderData: {},
            designerList: []
        }
    },
    created() {
        this.getList();
        apiDesignerList().then(res => {
            this.designerList = res
        })
    },
    methods: {
        editOrder(detail) {
            console.log('detail', detail)
            this.title = '修改';
            this.show = true;
            this.orderData = detail;
        },
        getList() {
            if (this.loading == true) return;
            this.loading = true;
            apiDeliverList({
                deliver_status: this.status,
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                keyword: this.search.keyword,
                designer_id: this.search.designer_id,
                start_time: this.search.data[0],
                end_time: this.search.data[1]
            }).then(res => {
                this.pagination.total = res.total;
                this.list = res.list;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        save() {//提交
            apiDeliverSave(this.orderData).then(res => {
                console.log('res', res)
                this.show = false;
                this.$message.success('修改成功');
                // this.getList();
            })
        },
        changeOperateTime() {
            this.search.data = value;
        },
        change(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getList();
        },
        searchAct() {
            this.pagination.current = 1;
            this.getList();
        },
        cancelAct() {
            this.search = {
                keyword: '',
                data: ['', ''],
                designer_id: 0
            };
            this.getList();
        },
        // 代码复制
        async copyCode(copyText) {
            if (copyText) {
                try {
                    await navigator.clipboard.writeText(copyText);
                    this.$message.success('复制成功！');
                } catch (err) {
                    this.$message.error('复制失败！');
                }
            }
        },
        toLogin(record) {
            apiDeliverLogin({
                order_id: record.order_id
            }).then(res => {
                console.log('res', res)
                // let href=record.production_address + "auth?shop_token="+encodeURIComponent(record.shop_token);
                window.open(res.url);
            })
        },
    }
}
</script>

<style></style>
